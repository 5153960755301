import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { ISEOProps, ISEODataProps } from "./ISEOProps";
import favicon from "../../../images/favicon1.png";
import { useVirtualPageView } from "../../../hooks/useVirtualPageView";
import { useLocation } from "@reach/router";
import { getLoggedStatus } from "../../../utils/helpers";
import { addKoddiPageTag } from "../../../utils/KoddiTagsGTM";

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

const SEO: React.FC<any> = (props: ISEOProps) => {
  const data: ISEODataProps = useStaticQuery(detailsQuery);
  const _title = props.title ? props.title : data.site.siteMetadata.title;
  const _description = props.description
    ? props.description
    : data.site.siteMetadata.description;
  const _keywords = props.keywords ? props.keywords : [];
  const _meta = props.meta ? props.meta : [];
  const lang = props.lang ? props.lang : "en";
  const includeLeaflet = props.includeLeaflet || false;
  const isPerformanceCookiesEnabled =
    typeof window !== "undefined" &&
    window?.OnetrustActiveGroups?.includes("C0002")
      ? true
      : false;
  useVirtualPageView(
    _title,
    props.setDL,
    props.crsCode,
    props.city,
    props.brand,
    props.brandPage
  );
  const [razorScript, setRazorScript] = React.useState(false);
  const [showScript, setShowScript] = React.useState(false);
  const location = useLocation();
  const oneTrustScriptDomain = process.env.GATSBY_ONE_TRUST_SCRIPT_DOMAIN;
  const OneTrustScripts = [];
  if (process.env.GATSBY_ONETRUST_SCRIPT === "ENABLED") {
    OneTrustScripts.push(
      <script
        key="oneTrust"
        src={"https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"}
        type="text/javascript"
        data-domain-script={oneTrustScriptDomain}
      />,
      <script key="oneTrustWrapper" type="text/javascript">
        {`
        function OptanonWrapper() { }
        `}
      </script>
    );
  }

  const init = async () => {
    props.koddiTitle &&
      (await addKoddiPageTag(props.location, props.koddiTitle, props.crsCode));
    setShowScript(true);
  };
  React.useEffect(() => {
    setTimeout(() => {
      const loggedState = getLoggedStatus();
      setRazorScript(true);

      window.dataLayer.push({
        event: "Engaged User",
        loggedState: loggedState,
        pageTitle: _title,
        pageUrl: location.href,
      });
    }, 40000);
  }, []);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      try {
        init();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }
  }, []);

  return (
    <Helmet
      async={true}
      htmlAttributes={{
        lang,
      }}
      title={_title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: _description,
        },
        {
          property: `og:title`,
          content: _title,
        },
        {
          property: `og:description`,
          content: _description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: _title,
        },
        {
          name: `twitter:description`,
          content: _description,
        },
      ]
        .concat(
          _keywords.length > 0
            ? {
                name: `keywords`,
                content: _keywords.join(`, `),
              }
            : []
        )
        .concat(_meta)}
    >
      <link rel="icon" href={favicon} />
      <meta name="description" content={_description} />
      <script>
        {`
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', { 
                  ad_storage: "granted", 
                  analytics_storage: "granted",
                  functionality_storage: "granted", 
                  personalization_storage: "granted", 
                  security_storage: "granted", 
                 'wait_for_update': 500 
            });
        `}
      </script>
      {/*One Trust Scripts */}
      {OneTrustScripts}
      {/* Ab Tasty Tag */}
      <script
        type="text/javascript"
        src={`https://try.abtasty.com/${process.env.GATSBY_ABTASTY_TAG_ID}.js`}
      ></script>
      {/* Google Tag Manager */}

      {showScript && (
        <script async>
          {`
            (function(w,d,s,l,i){console.log('GTM - SCRIPT -', new Date());w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GATSBY_GTM_ID}');
          `}
        </script>
      )}
      {/* End Google Tag Manager */}
      {/* Google Tag Manager */}
      <noscript>
        {`
            <iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTM_ID}"
            height="0" width="0" style="display:none; visibility:hidden" aria-hidden="true"></iframe>
            `}
      </noscript>
      {/* End Google Tag Manager */}

      {showScript ? (
        isPerformanceCookiesEnabled ? (
          <script async>
            {`
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            var gaId = '${process.env.GATSBY_GA_ID}';
            ga('create', gaId, 'auto');
          `}
          </script>
        ) : (
          <script async>
            {`
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
            var gaId = '${process.env.GATSBY_GA_ID}';
            var GA_LOCAL_STORAGE_KEY = 'ga_clientId';
            if (window.localStorage) {
              ga('create', 'gaId', {
                'storage': 'none',
                'clientId': localStorage.getItem(GA_LOCAL_STORAGE_KEY)
              });
              ga(function(tracker) {
                localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
              });
            }
          `}
          </script>
        )
      ) : null}
      {/* Below gives the browser instructions on how to control the page's dimensions and scaling. */}
      {/* Global site tag (gtag.js) - Google Marketing Platform  */}
      {razorScript && (
        <script
          defer
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_RAZORFISH_GTM_ID}`}
        ></script>
      )}
      {razorScript && (
        <script defer>
          {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config','${process.env.GATSBY_RAZORFISH_GTM_ID}');
                `}
        </script>
      )}
      {
        <script async type="text/plain" className="optanon-category-C0002">
          {`
              (function(){
              var qtm = document.createElement('script'); qtm.type = 'text/javascript'; qtm.async = 1;
              qtm.src = 'https://cdn.quantummetric.com/qscripts/quantum-sonesta${process.env.GATSBY_QM_SCRIPT_ENV}.js';
              var d = document.getElementsByTagName('script')[0];
              !window.QuantumMetricAPI && d.parentNode.insertBefore(qtm, d);
              })();
              `}
        </script>
      }
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5"
      />

      {includeLeaflet && (
        <link
          rel="stylesheet"
          as="style"
          href="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.min.css"
        />
      )}
      {props.canonicalUrl ? (
        <link rel="canonical" href={props.canonicalUrl} />
      ) : null}
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="stylesheet"
        // media="print"
        // onLoad={() => {
        //   this.onLoad=null;
        //   this.removeAttribute('media');
        // }}
        as="style"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap&subset=latin"
      />

      {props.noIndex ? <meta name="robots" content="noindex" /> : null}
      {props.noFollow ? <meta name="robots" content="nofollow" /> : null}
    </Helmet>
  );
};

export const defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

export const propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
