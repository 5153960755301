import { deviceType } from "react-device-detect";
import Axios from "axios";
import { store } from "../redux/store";
import {v4 as uuidv4} from "uuid";
import { Storage } from "../utils/storage";

export const addKoddiTransactionTag = async (reservation) => {
    let isNewBooking = true;
    let resDetail = reservation[0].details;
    const userGUID = sessionStorage['koddiUUID'];
    const res = await Axios.get('https://geolocation-db.com/json/');
    if(sessionStorage['koddiLastReservationId']) {
        reservation.forEach((r, i) => {
          if(r.crs_reservation_id === sessionStorage['koddiLastReservationId']) {
            isNewBooking = false;
          }
        });
      }
    sessionStorage['koddiLastReservationId'] = reservation[0].crs_reservation_id;
    if(isNewBooking){
        reservation.forEach((r, rIndex) => {
            const hotelId = r.details.HotelCode
            const bookingId = r.crs_reservation_id;
            const checkIn = r.details.Start;
            const checkOut = r.details.End;
            const room = 1;
            const adults = r.details.Count.Adult;
            const children = r.details.Count.Child;
            const totalPrice = r.details.RoomRates ? r.details.RoomRates[0].Total : 0;
            const transactionURL = `https://beam.koddi.com/travel?action=​booking​&clientName=Sonesta&domain=www.sonesta.com&pageName=reservationConfirmation&userGuid=${userGUID ? userGUID : ""}&hotelId=${hotelId ? hotelId:  ""}&checkIn=${checkIn ? checkIn : ""}&checkOut=${checkOut ? checkOut : ""}&totalPrice=${totalPrice ? totalPrice : ""}&currency=USD&bookingId=${bookingId ? bookingId : ""}&rooms=${room ? room : ""}&adults=${adults ? adults : ""}&children=${children ? children : ""}&userIP=${res?.data?.IPv4 ? res?.data?.IPv4 : ""}&userAgent=${navigator.userAgent ? navigator.userAgent : ""}&deviceType=${deviceType ? deviceType : ""}`;
            const script = document.createElement("script");
            script.src = transactionURL;
            script.async = true;
            document.body.appendChild(script);
        })
    }
}

export const addKoddiPageTag = async (location, pageName, hotelID) => {
    const state = store.getState();
    const search = state?.search;
    const hotelCode = hotelID ? hotelID : '';
    const searchString = location?.search ||  "";
    const searchParams = new URLSearchParams(searchString);
    const queryParams = Object.fromEntries(searchParams);
    const queryParamsLowercase = Object.keys(queryParams).reduce((qp, key) => {
      qp[key.toLowerCase()] = queryParams[key];
      return qp;
    }, {});
    let gclid;
    let hmGUID;
    let koddiUUID;
    let koddiUserIP;
    const crsCode = queryParamsLowercase.crscode ? queryParamsLowercase.crscode : hotelCode;
    if(queryParamsLowercase.gclid || queryParamsLowercase.hmguid){
        const sessionUUID = uuidv4();
        gclid = queryParamsLowercase.gclid;
        hmGUID = queryParamsLowercase.hmguid;
        koddiUUID = sessionUUID;
        sessionStorage['gclid'] = gclid;
        sessionStorage['hmGUID'] = hmGUID;
        sessionStorage['koddiUUID'] = sessionUUID;
    } else {
        gclid = sessionStorage['gclid'];
        hmGUID = sessionStorage['hmGUID'];
        koddiUUID = sessionStorage['koddiUUID'];
    }
    if(sessionStorage['koddiUserIP']){
        koddiUserIP = sessionStorage['koddiUserIP'];
    }else{
        try{
            const res = await Axios.get('https://api.ipify.org?format=json');
            sessionStorage['koddiUserIP'] = res?.data?.ip;
            koddiUserIP = res?.data?.ip;
            Storage.SetLocalStorageValue("koddiUserIP", res?.data?.ip);
        }catch (err) {
            koddiUserIP = Storage.GetLocalStorageValue("koddiUserIP");
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }
        const adults = search.rooms.reduce((adult, room) => {
            return adult + room.adults;
        }, 0); 
        const children = queryParamsLowercase.children ? queryParamsLowercase.children : search.rooms.reduce((child, room) => {
            return child + room.children;
        }, 0); 
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'koddi',
            pageName: pageName ? pageName : "",
            userGuid: koddiUUID ? koddiUUID : "",
            hotelId: crsCode ? crsCode : "",
            checkIn: search.checkin ? search.checkin : "",
            checkOut: search.checkout ? search.checkout : "",
            destCity: search.searchString ? search.searchString : "",
            rooms: search.rooms.length ? search.rooms.length : "1",
            adults: adults ? adults : "1",
            children: children ? children : "0",
            userIP: koddiUserIP ? koddiUserIP : "",
            userAgent: navigator.userAgent ? navigator.userAgent : "",
            deviceType: deviceType ? deviceType : "",
            tm_hmGUID: hmGUID ? hmGUID :  "",
            gclid:  gclid ? gclid : "",
          });
    
}