import React from "react";
import { IBrandSearchProps } from "./BrandSearchProps";
import Container from "react-bootstrap/Container";
import SEO from "../../global/SEO/SEO";
import SearchResult from "../../common/Search/SearchResult/SearchResult";
import SearchBreadCrumb from "../../common/SearchBreadCrumb/SearchBreadCrumb";

const BrandSearch = ({
  brand,
  location,
  hotelSearchFilters,
}: IBrandSearchProps) => {
  return (
    <React.Fragment>
      <SEO title={`Search ${brand.name} hotels`} includeLeaflet={true} />
      <SearchBreadCrumb location={location} step="1" />
      <Container>
        <SearchResult
          sortBy="brand"
          location={location}
          hotelSearchFilters={hotelSearchFilters}
        />
      </Container>
    </React.Fragment>
  );
};
export default BrandSearch;
