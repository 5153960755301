import * as React from "react";
// import Container from "react-bootstrap/Container";
import { container } from "./BrandDefault.module.scss";
import BackgroundImage from "gatsby-background-image";
// import { getImage } from "gatsby-plugin-image";
import {IMainContainerProps} from "../../../@types/IBrand";
import { convertToBgImage } from "gbimage-bridge";

export const MainContainer = (props: IMainContainerProps) => {
    // const _image = getImage(props.childImageSharp);
    const _bgImage = props.background ? convertToBgImage(props.background.fluid) : {};
    if(!props.background?.fluid) return <div>{props.children}</div>;
    return (
        <BackgroundImage {..._bgImage}
            preserveStackingContext
            className={`${container} pt-4 pb-4 pt-md-5 pb-md-5 position-relative`}
        >
            {props.children}
        </BackgroundImage>);
};



