import React from "react";
import { Link } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IBrandDefaultProps } from "./BrandDefaultProps";
import { Constants } from "../../../@types/Constants";
import { IPara } from "../../../@types/IBrand";
import Container from "react-bootstrap/Container";
import { MainContainer } from "./BrandDefaultHelpers";

import SEO from "../../global/SEO/SEO";
import TemplateOffersList from "../../common/Offers/TemplateOffersList/TemplateOffersList";
import { getBackgroundStyles } from "../../paragraphs/ParagraphBackground/ParagraphBackground";
import SubTitle from "../../global/SubTitle/SubTitle";
import ParagraphAnchorLink from "../../common/ParagraphAnchorLink/ParagraphAnchorLink";
import { getParagraph } from "../../../utils/paragraphHelpers";
import { IOffers } from "../../../@types/IOffers";

const BrandDefault = ({ brand, location, siteOffers }: IBrandDefaultProps) => {
  const paragraphs = brand.relationships.paragraphs
    ? brand.relationships.paragraphs
    : [];
  const description =
    brand.field_meta_description !== null ? brand.field_meta_description : null;
  const color = brand?.field_color ? brand.field_color.color : null;
  const menu: { link: string; id: string }[] = [];
  paragraphs.forEach((paragraph: IPara) => {
    const type = paragraph.type;
    if (type === "paragraph__photo_gallery") {
      menu.push({ link: "Gallery", id: paragraph.id });
    }
    if (type === "paragraph__featured_destinations") {
      menu.push({ link: "Featured Destinations", id: paragraph.id });
    }
    if (type === "paragraph__featured_properties") {
      menu.push({ link: "Featured Hotels", id: paragraph.id });
    }
    if (type === "paragraph__offers") {
      menu.push({ link: "Special Offers", id: paragraph.id });
    }
  });
  return (
    <React.Fragment>
      <SEO
        title={`${brand.name}`}
        description={description}
        brand={brand.name}
        brandPage={true}
      />
      <h1 className="visually-hidden">{brand.name}</h1>
      {paragraphs.length
        ? paragraphs.map((paragraph: IPara, index: number) => {
            if (paragraph.type === "paragraph__offers") {
              const background = getBackgroundStyles(
                paragraph.relationships.background
              );
              // Fallback to theme color if no specific background set.
              if (background && !background.color && color) {
                background.color = color;
              }
              let offers = paragraph.relationships?.offers; // ? paragraph.relationships?.offers : siteOffers;
              offers =
                offers &&
                offers.filter(
                  (offer: {
                    relationships: {
                      field_site_list?: { machine_name: string | undefined };
                    };
                  }) =>
                    offer.relationships.field_site_list?.machine_name ==
                    process.env.GATSBY_SITE_MACHINE_NAME
                );
              const isBackgroundExist = color && background?.fluid;
              const brandPath = brand?.path && brand.path.alias;
              if (offers && offers.length) {
                offers.forEach((offer: IOffers) => {
                  const offerPath =
                    brandPath && offer.path.alias.indexOf(brandPath) == -1
                      ? `${brandPath}${offer.path.alias}`
                      : offer.path.alias;
                  offer.path.alias = offerPath;
                });
              }

              return (
                offers.length && (
                  <MainContainer
                    background={background}
                    key={`cont-${paragraph.id}-${index}`}
                  >
                    <ParagraphAnchorLink id={paragraph.id} />
                    <Container fluid="sm" className="p-0">
                      <Row className="mb-4 mb-md-5">
                        <Col className="text-center">
                          <SubTitle
                            className={isBackgroundExist ? "text-white" : ""}
                          >
                            {paragraph.sectionTitle}
                          </SubTitle>
                          <h2 className={isBackgroundExist ? "text-white" : ""}>
                            {paragraph.title}
                          </h2>
                          {paragraph.description &&
                            paragraph.description.length > 0 && (
                              <p
                                className={
                                  isBackgroundExist ? "text-white" : ""
                                }
                                dangerouslySetInnerHTML={{
                                  __html: paragraph.description,
                                }}
                              />
                            )}
                          {paragraph.link ? (
                            <Link
                              to={paragraph.link.url}
                              className={`teaser-link ${
                                isBackgroundExist ? "text-white" : ""
                              }`}
                            >
                              {paragraph.link.title}
                            </Link>
                          ) : (
                            <Link
                              to={`${brandPath}/offers`}
                              className={`teaser-link ${
                                isBackgroundExist ? "text-white" : ""
                              }`}
                            >
                              Explore All Offers
                            </Link>
                          )}
                        </Col>
                      </Row>
                      <TemplateOffersList
                        key={`offer-${paragraph.id}-${index}`}
                        offers={offers}
                        brand={brand}
                        theme={color ? "dark" : null}
                        location={location}
                        color={color}
                      />
                    </Container>
                  </MainContainer>
                )
              );
            }
            if (paragraph.type === Constants.PARAGRAPH_PAGE_BANNER) {
              return getParagraph(paragraph, menu.length && { menu: menu });
            }
            if (paragraph.type === "paragraph__book_now_banner") {
              return getParagraph(paragraph, { brand: brand });
            } else {
              let paragraphProp = {
                location: location,
                color: color,
                brand: brand,
              };
              if (paragraph.type === "paragraph__photo_gallery") {
                paragraphProp = { ...paragraphProp, ...{ imgCountPerRow: 1 } };
              }
              return getParagraph(paragraph, paragraphProp);
            }
          })
        : null}
    </React.Fragment>
  );
};

export default BrandDefault;
