import React from "react";
import { useLocation } from '@reach/router';
import { getLoggedStatus } from "../utils/helpers";
import { sendLoginStateInGA } from "../utils/datalayers";

export const useVirtualPageView = (title: string, setDL = true, crsCode = '', city = '', brand = '', brandPage = false) => {
  const location = useLocation();
  React.useEffect(() => {
    try {
        if (setDL) {
          window.dataLayer = window.dataLayer || [];
          const loggedState = getLoggedStatus();
          // sendLoginStateInGA();
          const dlObj = {
            event: "virtualPageView",
            loggedState: loggedState,
            pageTitle: title,
            pageUrl: location.href,
            ...(crsCode && {crsCode: crsCode}),
            ...(crsCode && city && { city }),
            ...((crsCode || brandPage) && brand && { brand })
          };
          window.dataLayer.push(dlObj);
      }
        
    } catch (err) {
      // eslint-disable-next-line no-console
        console.error(err);
    }
  }, []);
};

